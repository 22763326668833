import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	// faArrowLeft,
	// faArrowRightFromBracket,
	faArrowsRotate,
} from "@fortawesome/pro-regular-svg-icons";
import { faWifi, faWifiSlash } from "@fortawesome/pro-solid-svg-icons";
import { Network } from "@capacitor/network";

import { userData } from "../../providers/companyInfo";
import {
	checkNetworkStatus,
	// deleteFile,
} from "../../providers/useCapacitorStorage";
// import { faBell } from "@fortawesome/pro-regular-svg-icons";

export default function Header(props) {
	const { pageHeaderClass, pageHeaderIcon, title, subtitle, pageId } = props;
	// const navigate = useNavigate();
	const [networkStatus, setNetworkStatus] = useState(false);

	// const handleLogout = () => {
	// 	deleteFile("dsac_survey/form_list.txt").then((res) => {
	// 		deleteFile("dsac_survey/survey_pending_list.txt").then((res) => {
	// 			localStorage.clear();
	// 			window.location.replace("/");
	// 		});
	// 	});
	// };

	useEffect(() => {
		Network.addListener("networkStatusChange", (res) => {
			setNetworkStatus(res.connected);
		});

		checkNetworkStatus().then((res) => {
			setNetworkStatus(res.connected);
		});

		return () => {};
	}, []);

	return (
		<Layout.Header>
			<div className="header-left-menu">
				<PageHeader
					className={pageHeaderClass}
					title={
						<>
							<div className="ant-page-header-icon">
								<FontAwesomeIcon icon={pageHeaderIcon} />
							</div>
							<div className="ant-page-header-text">
								<div className="sub-title" id="pageHeaderSubtitle">
									{pageId === "PageDashboard"
										? `Hi ${userData().firstname}!`
										: subtitle}
								</div>

								<div className="title" id="pageHeaderTitle">
									{pageId === "PageDashboard" ? "Your Surveys" : title}
								</div>
							</div>
						</>
					}
				/>
			</div>

			<div className="header-right-menu">
				{/* {pageId !== "PageSurveyPendingView" && pageId !== "PageSurveyForm" ? (
					<div className="icon-menu-logout" onClick={handleLogout}>
						<FontAwesomeIcon icon={faArrowRightFromBracket} />
					</div>
				) : (
					<div className="icon-menu-back" onClick={() => navigate(-1)}>
						<FontAwesomeIcon icon={faArrowLeft} />
					</div>
				)} */}

				<div
					className={`icon-menu-network ${
						networkStatus ? "online" : "offline"
					}`}
				>
					<FontAwesomeIcon icon={networkStatus ? faWifi : faWifiSlash} />
				</div>
				<div className={`icon-menu-sync hide`} id="datasync">
					<FontAwesomeIcon icon={faArrowsRotate} spin />
				</div>
			</div>
		</Layout.Header>
	);
}

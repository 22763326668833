import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Row, Col, Carousel, Badge, notification } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/pro-solid-svg-icons";

import carouselImg from "../../../assets/img/survey.png";
import { GET } from "../../../providers/useAxiosQuery";
import {
	checkPermissions,
	deleteFile,
	readFile,
	requestPublicStoragePermissions,
	setFileStorage,
} from "../../../providers/useCapacitorStorage";
import SurveyList from "./SurveyList";

export default function PageDashboard() {
	const navigate = useNavigate();

	requestPublicStoragePermissions();

	const [formList, setFormList] = useState([]);
	const [surveyPendingList, setSurveyPendingList] = useState([]);

	GET(
		`api/form?from=SurveyMobile`,
		"form_list",
		(res) => {
			if (res && res.data) {
				let data = res.data;

				let datasync = document.getElementById("datasync");
				if (datasync) {
					datasync.classList.remove("hide");
					console.log("datasync");
				}

				let dataSurveyFiltered = data.filter(
					(f) => f.form_question_categories.length > 0
				);

				setFormList(dataSurveyFiltered);

				checkPermissions().then((res1) => {
					if (res1) {
						if (res1.publicStorage === "granted") {
							setFileStorage(
								"dsac_survey",
								"form_list",
								dataSurveyFiltered
							).then((res2) => {
								if (!res2 && !res2.uri) {
									deleteFile("dsac_survey/form_list.txt").then((res3) => {
										if (!res3) {
											setFileStorage(
												"dsac_survey",
												"form_list",
												dataSurveyFiltered
											).then((res4) => {
												if (res2 && res2.uri) {
													setTimeout(() => {
														if (datasync) {
															datasync.classList.add("hide");
														}
													}, 1000);
												}
											});
										} else {
											notification.error({
												message: "Delete Survey List",
												description: JSON.stringify(res3),
											});
										}
									});
								} else {
									setTimeout(() => {
										if (datasync) {
											datasync.classList.add("hide");
										}
									}, 1000);
								}
							});
						}
					}
				});
			}
		},
		false
	);

	useEffect(() => {
		checkPermissions().then((res1) => {
			if (res1) {
				if (res1.publicStorage === "granted") {
					readFile("dsac_survey/form_list.txt").then((res2) => {
						if (res2 && res2.data) {
							res2 = JSON.parse(res2.data);
							setFormList(res2);
						}
					});

					readFile("dsac_survey/survey_pending_list.txt").then((res2) => {
						if (res2 && res2.data) {
							res2 = JSON.parse(res2.data);
							setSurveyPendingList(res2);
						}
					});
				}
			}
		});

		return () => {};
	}, []);

	return (
		<Row gutter={[12, 12]} justify="center">
			<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
				<Carousel autoplay className="intro">
					<div className="carousel-item-home">
						<img src={carouselImg} alt="" />
					</div>
				</Carousel>
			</Col>

			<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
				<Card
					className="survey-list-wrapper"
					title={
						<>
							<div className="title">SURVEY LIST</div>
							<div className="action cursor-pointer">
								{surveyPendingList.length > 0 ? (
									<Badge
										count={surveyPendingList ? surveyPendingList.length : 0}
										overflowCount={99}
										onClick={() => navigate("/survey/pending")}
									>
										<FontAwesomeIcon
											icon={faSquare}
											style={{
												color: "#2d60e2",
											}}
										/>
									</Badge>
								) : null}
							</div>
						</>
					}
					bordered={false}
				>
					<SurveyList formList={formList} />
				</Card>
			</Col>
		</Row>
	);
}

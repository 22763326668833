import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Form, Row, Typography, notification } from "antd";

import { readFile } from "../../../providers/useCapacitorStorage";
import { userData } from "../../../providers/companyInfo";
import QuestionOption from "./components/QuestionOption";
import dayjs from "dayjs";
import ModalPreviewAnswer from "./components/ModalPreviewAnswer";

export default function PageSurveyForm() {
	const params = useParams();
	const navigate = useNavigate();
	const [form] = Form.useForm();

	const [toggleModalPreview, setToggleModalPreview] = useState(false);
	const [dataSource, setDataSource] = useState(null);

	useEffect(() => {
		let paramsId = params.id;

		readFile("dsac_survey/survey_pending_list.txt").then((res) => {
			if (res && res.data) {
				res = JSON.parse(res.data);
				let findData = res.find((f) => f.index === parseInt(paramsId));

				if (findData) {
					let question = findData.form_question_categories[0].form_questions[0];

					setDataSource(findData);
					form.setFieldsValue({ [`q_${question.id}`]: question.answer });
				} else {
					notification.warning({
						message: "Warning",
						description: "No data was found.",
						duration: 10,
					});
					navigate("/");
				}
			} else {
				notification.warning({
					message: "Warning",
					description:
						"Unable to read the file: either no permission or the file was not found.",
					duration: 10,
				});
			}
		});
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.id]);

	const onFinish = (values) => {
		let form_question_categories = dataSource.form_question_categories.map(
			(item) => {
				let form_questions = item.form_questions.map((item2) => {
					let answer = values[`q_${item2.id}`] ?? "";

					if (item2.question_type === "date") {
						if (values[`q_${item2.id}`]) {
							answer = dayjs(values[`q_${item2.id}`]).format("YYYY-MM-DD");
						}
					}

					return {
						...item2,
						user_id: userData().id,
						form_id: dataSource.id,
						date_start_answer: dataSource.date_start_answer,
						date_end_answer: dayjs().format("YYYY-MM-DD HH:mm:ss"),
						form_question_category_id: item.id,
						form_question_id: item2.id,
						answer: Array.isArray(answer) ? JSON.stringify(answer) : answer,
						created_by: userData().id,
						created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
						updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
					};
				});

				return {
					...item,
					form_questions,
				};
			}
		);

		let dataSourceCopy = {
			...dataSource,
			form_question_categories,
			date_end_answer: dayjs().format("YYYY-MM-DD HH:mm:ss"),
			survey_status: "Ready to Upload",
		};

		console.log("dataSourceCopy", dataSourceCopy);
		setDataSource(dataSourceCopy);
		setTimeout(() => {
			setToggleModalPreview(true);
		}, 500);
	};

	return (
		<Form form={form} onFinish={onFinish}>
			<Row gutter={[12, 0]}>
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<div className="form-name">
						<Typography.Title level={5}>
							{dataSource && dataSource.form_name}
						</Typography.Title>
					</div>
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					{dataSource && dataSource.form_question_categories
						? dataSource.form_question_categories.map((item, index) => {
								return (
									<Card key={index} title={item.category}>
										<Row gutter={[12, 12]}>
											{item.form_questions && item.form_questions
												? item.form_questions.map((item2, index2) => {
														return (
															<Col
																xs={24}
																sm={24}
																md={24}
																lg={24}
																xl={24}
																key={index2}
															>
																<div
																	className="quill-output question"
																	dangerouslySetInnerHTML={{
																		__html: item2.question,
																	}}
																/>

																<QuestionOption data={item2} />
															</Col>
														);
												  })
												: []}
										</Row>
									</Card>
								);
						  })
						: []}
				</Col>

				<Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center">
					<Button
						htmlType="submit"
						type="primary"
						className="btn-main-primary br-30"
						size="large"
					>
						Preview
					</Button>
				</Col>
			</Row>

			<ModalPreviewAnswer
				toggleModalPreview={toggleModalPreview}
				setToggleModalPreview={setToggleModalPreview}
				dataSource={dataSource}
			/>
		</Form>
	);
}

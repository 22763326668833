import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faUpload } from "@fortawesome/pro-regular-svg-icons";
import { Card, Table, Button, notification, Row, Col } from "antd";
import dayjs from "dayjs";
import {
	checkNetworkStatus,
	readFile,
	setFileStorage,
} from "../../../providers/useCapacitorStorage";
import { POST } from "../../../providers/useAxiosQuery";
import notificationErrors from "../../../providers/notificationErrors";
import { userData } from "../../../providers/companyInfo";

export default function PageSurveyPending() {
	const navigiate = useNavigate();

	const [dataSource, setDataSource] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);

	useEffect(() => {
		readFile("dsac_survey/survey_pending_list.txt").then(async (res) => {
			if (res && res.data) {
				res = JSON.parse(res.data);
				setDataSource(res);
			}
		});

		return () => {};
	}, []);

	const { mutate: mutateSurveyBulk, isLoading: isLoadingSurveyBulk } = POST(
		`api/form_question_answer_bulk_store`,
		"form_question_answer_bulk_store"
	);

	const handleUploadBulk = () => {
		let data = [];
		selectedRowKeys.forEach((item) => {
			let findData = dataSource.find((f) => f.index === item);
			if (findData) {
				findData.form_question_categories.forEach((item2) => {
					let questionAnswers = [];
					item2.form_questions.forEach((item3) => {
						questionAnswers.push({
							user_id: userData().id,
							form_id: findData.id,
							date_start_answer: findData.date_start_answer,
							date_end_answer: findData.date_end_answer,
							form_question_category_id: item2.id,
							form_question_id: item3.id,
							answer: Array.isArray(item3.answer)
								? JSON.stringify(item3.answer)
								: item3.answer,
							created_by: userData().id,
							created_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
							updated_at: dayjs().format("YYYY-MM-DD HH:mm:ss"),
						});
					});
					data.push(...questionAnswers);
				});
			}
		});

		checkNetworkStatus().then((res) => {
			if (res.connected) {
				mutateSurveyBulk(
					{ data: data },
					{
						onSuccess: (res2) => {
							if (res2.success) {
								notification.success({
									message: "Survey",
									description: res2.message,
								});

								setFileStorage("dsac_survey", "survey_pending_list", []).then(
									() => {
										setDataSource([]);
										setSelectedRowKeys([]);
									}
								);
							} else {
								notification.error({
									message: "Survey Pending",
									description: res2.message,
								});
							}
						},
						onError: (err) => {
							notificationErrors(err);
						},
					}
				);
			} else {
				notification.error({
					message: "Survey Pending",
					description:
						"I'm sorry, but you are currently not connected to the internet. Please reconnect to the internet and attempt your request again.",
				});
			}
		});
	};

	const columns = [
		{
			title: "Action",
			key: "action",
			render: (_, record) => {
				return (
					<>
						<Button
							className="btn-add-tertiary"
							// onClick={() => }
							type="link"
							icon={<FontAwesomeIcon icon={faEye} />}
							onClick={() => navigiate(`/survey/${record.index}`)}
						/>
					</>
				);
			},
		},
		{
			title: "Form Name",
			dataIndex: "form_name",
			key: "form_name",
		},
		{
			title: "Date Start Answer",
			dataIndex: "date_start_answer",
			key: "date_start_answer",
		},
		{
			title: "Date End Answer",
			dataIndex: "date_end_answer",
			key: "date_end_answer",
		},
		{
			title: "Status",
			dataIndex: "survey_status",
			key: "survey_status",
		},
	];
	return (
		<Row gutter={[12, 12]}>
			{selectedRowKeys.length > 0 ? (
				<Col xs={24} sm={24} md={24} lg={24} xl={24}>
					<Button
						icon={<FontAwesomeIcon icon={faUpload} />}
						className="btn-main-primary br-20"
						loading={isLoadingSurveyBulk}
						onClick={handleUploadBulk}
					>
						Upload
					</Button>
				</Col>
			) : null}

			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Card>
					<Table
						dataSource={dataSource}
						columns={columns}
						rowKey="index"
						scroll={{ x: "max-content" }}
						rowSelection={{
							selectedRowKeys,
							onSelect: (record, selected, selectedRows) => {
								let selectedRowKeysCopy = [...selectedRowKeys];
								if (selected) {
									if (record.survey_status === "Ready to Upload") {
										console.log("record.index", record.index);
										selectedRowKeysCopy.push(record.index);
									} else {
										notification.error({
											message: "Error",
											description: "Please finish answer the survey.",
										});
									}
								} else {
									selectedRowKeysCopy = selectedRowKeysCopy.filter(
										(f) => f !== parseInt(record.index)
									);
								}
								setSelectedRowKeys(selectedRowKeysCopy);
							},
							onSelectAll: (selected, selectedRows, changeRows) => {
								let selectedRowsFiltered = selectedRows
									.filter((item) => item.survey_status === "Ready to Upload")
									.map((item) => item.index);
								if (selected) {
									if (selectedRowKeys.length !== selectedRowsFiltered.length) {
										setSelectedRowKeys(selectedRowsFiltered);
									} else {
										setSelectedRowKeys([]);
									}
								} else {
									setSelectedRowKeys([]);
								}
							},
						}}
					/>
				</Card>
			</Col>
		</Row>
	);
}
